import { RuleTypes } from '@/utilities/rules';
import { isNil } from 'lodash';
import states from '../../pages/api/content/states';
import countriesJson from '../../pages/api/content/countries.json';
import { isEmpty } from './validation';

/**
 * Mapping for Loqate api response.
 * Key - what we have in config json files (matches BE locations schema),
 * Value - matching field from the Loqate response object.
 */
const autoCompleteMappings = {
  line1: 'Line1',
  line2: 'Line2',
  town: 'City',
  area: 'Province',
  postcode: 'PostalCode',
  country: 'CountryIso2',
};

/**
 * Value modifiers for address fields
 * can be used to modify the value before sending it to the BE
 * currently needed for postcode field for USPS validation can be extended if needed
 * When we get the postcode from the autocomplete API, we only want the first 5 characters
 */
const modifierFunctions = {
  postcode: (value) => value.substring(0, 5),
};

/**
 * Helper for adding address changes to ChangesContext
 * @param {{}} autocompleteResponse Address object recieved from autocomplete api.
 * @param {Function} addChange Passed in method from ChangesContext
 * @param {Array} valueModifiers Array of modifierFunctions keys to modify the value before sending it to the BE
 */
const generateAddressChange = (autocompleteResponse, addChange, valueModifiers) => {
  Object.entries(autoCompleteMappings).forEach(([key, value]) => {
    if (valueModifiers?.includes(key) && !isNil(modifierFunctions[key])) {
      addChange({
        type: 'WAREHOUSE',
        key: {
          place: {
            address: {
              [key]: '%VALUE%',
            },
          },
        },
        value: modifierFunctions[key](autocompleteResponse[value]) || '',
        valueId: key,
      });
      return;
    }

    addChange({
      type: 'WAREHOUSE',
      key: {
        place: {
          address: {
            [key]: '%VALUE%',
          },
        },
      },
      value: autocompleteResponse[value] || '',
      valueId: key,
    });
  });
};

/**
 * There are multiple values here because different carriers have different wording.
 * key - field name from BE locations schema;
 * value - Array of possible field mappings that we send to 'validate-address';
 */
const addressMappingForCarriers = {
  line1: ['Address1'],
  line2: ['Address2'],
  town: ['City', 'suburb'],
  area: ['State', 'state'],
  postcode: ['Zip5', 'postcode'],
};

/**
 * Generates a string of address information for url parameters
 *
 * @param {Object} addressFields object containing address info e.g. { city: 'Boston', state: 'MA' }
 * @return {string} address string for url parameter use e.g. city=Boston&state=MA
 */
function getUserAddressURLString(addressFields) {
  if (!addressFields || Object.keys(addressFields).length === 0) return '';

  const addressLines = [];
  Object.keys(autoCompleteMappings).forEach((field) => {
    const addressValue = addressFields[field];
    if (addressValue) {
      const mappedFields = addressMappingForCarriers[field];
      mappedFields?.forEach((fieldName) => {
        addressLines.push(`${fieldName}=${addressValue}`);
      });
    }
  });

  return addressLines
    .filter(Boolean)
    .join('&');
}

/**
 * Assign rules to warehouses
 * @return {array} Array of warehousesConfig
 * @param warehousesConfig Array of warehousesConfig
 * @param rulesConfig Array of rulesConfig
 */
export const assignRulesToWarehouses = (warehousesConfig, rulesConfig) => {
  const returnAddressRules = rulesConfig.filter(({ ruleType }) => ruleType === RuleTypes.RETURN_ADDRESS);
  const mapOfReturnAddressRules = returnAddressRules.reduce((total, current) => {
    const key = current.rule.event.params.actionValue.locationId;
    return {
      ...total,
      [key]: (total[key] || []).concat([current]),
    };
  }, {});
  return warehousesConfig.map((warehouse) => ({
    ...warehouse,
    rules: mapOfReturnAddressRules[warehouse.locationId] || [],
  }));
};

/**
 * Populates address country field with list of countries
 * @return {array} Array of address fields
 * @param fields Array of address fields from the config
 */
const populateCountries = (fields = []) => {
  const newFields = fields.map((address) => {
    if (address.type === 'dropdown' && address.params.valueId === 'country') {
      const items = Object.entries(countriesJson).map(([value, title]) => ({ value, title }));
      return { ...address, params: { ...address.params, items } };
    }
    return address;
  });
  return newFields;
};

/**
 * Generates address states dropdown options
 * @return {array} Array of dropdown options
 * @param fields Array of address fields from the config
 * @param carrierId
 */
const generateDropdownOptions = (fields = [], carrierId, locale) => {
  const newFields = fields.map((address) => {
    if (address.type === 'dropdown' && address.params.valueId === 'area') {
      let items = states[carrierId];
      if (locale === 'ja') {
        items = items.map((option) => ({ ...option, title: option.value }));
      }
      return { ...address, params: { ...address.params, items } };
    }
    if (address.type === 'dropdown' && address.params.valueId === 'country') {
      const [dropdownAddress] = populateCountries([address]);
      return dropdownAddress;
    }
    return address;
  });
  return newFields;
};

/**
 * Helper for carrier address validation
 * @return {{
 * found: boolean,
 * error: string,
 * infoMessage: string
 * invalidCarriers: Array<string>
 * }} Result of validation
 * @param {{}} validationResponse An object returned from the validate-address call
 * @param {{allowAnyValidCarrier: boolean, supportEmail: string, translate: Function}} options
 */
const checkAddressIsValidForCarriers = (
  validationResponse = {},
  {
    allowAnyValidCarrier,
    translate,
    supportEmail = '',
  } = {},
) => {
  const result = {
    found: validationResponse.found,
    error: '',
    infoMessage: '',
    invalidCarriers: validationResponse.invalidCarriers || [],
  };
  const invalidCarrierIds = isEmpty(validationResponse.invalidCarriers)
    ? null
    : validationResponse.invalidCarriers.join(', ');

  // when adding the first address for a merchant - check for ANY valid carrier
  if (allowAnyValidCarrier) {
    if (validationResponse.found && invalidCarrierIds) {
      result.infoMessage = translate('returnAddresses.cardAddress.carrierRestrictionsMessage')
        .replaceAll('{carrierIds}', invalidCarrierIds)
        .replace('{supportEmail}', supportEmail);
    }
    return result;
  }
  // otherwise should allow adding/updating an address only if ALL carriers are valid
  if (invalidCarrierIds) {
    result.found = false;
    result.error = translate('returnAddresses.cardAddress.carrierErrorMessage')
      .replaceAll('{carrierIds}', invalidCarrierIds)
      .replace('{supportEmail}', supportEmail);
  }
  return result;
};

const validateAddressesForCarriers = async ({
  warehouses: warehousesToCheck,
  validateCarrierRequest,
  validationCallback,
  onError = () => {},
}) => {
  try {
    await Promise.all(warehousesToCheck.map(async (warehouse) => {
      const addressString = getUserAddressURLString(warehouse.place?.address);
      const carrierValidation = await validateCarrierRequest({
        warehouseAddress: addressString,
        allowAnyValidCarrier: true,
      });
      validationCallback({
        locationId: warehouse.locationId,
        message: carrierValidation?.infoMessage,
        invalidCarriers: carrierValidation?.invalidCarriers,
      });
    }));
  } catch (error) {
    onError(error);
  }
};

export default {
  autoCompleteMappings,
  generateAddressChange,
  getUserAddressURLString,
  assignRulesToWarehouses,
  populateCountries,
  generateDropdownOptions,
  checkAddressIsValidForCarriers,
  validateAddressesForCarriers,
};
