import React, {
  useState, useEffect, createContext,
} from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { Base64 } from 'js-base64';
import getEnvVar from '@/utilities/environment-variable';
import sessionStorage, { useSessionAuthData, STORAGE_KEY_AUTH_DATA } from '@/utilities/sessionStorage';
import ExternalAuth from '@/utilities/authorisation';
import { isEmpty } from '@/utilities/validation';
import { includes, some } from 'lodash';
import { errorHandler } from '@/utilities/error-handling';

export const AuthContext = createContext();

const PERMISSIONS = [
  'auth_staff_password', // signup flow
  'auth_refresh_token', // signup flow
  'asset-management:read',
  'asset-management:write',
  'dashboard:read',
  'prereg:write',
  'prereg:read',
  'authmanagement_staff:read',
  'authmanagement_staff:write',
  'stores:read',
  'companies:read',
  'authmanagement_staff:password_reset',
  'authmanagement_staff:password_reset_trigger',
  'returns_portal_service:app:admin',
  'purchases:write',
  'orders:read',
  'features:read',
];

const staffPermissions = [
];

const ROLE_TYPES = {
  ADMIN: 'admin',
  MERCHANT: 'merchant',
  UNKNOWN: 'unknown',
};

const AuthContextProvider = ({ children, localConfig }) => {
  const router = useRouter();
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [tokenExpiryTime, setTokenExpiryTime] = useState(null);
  const [loginEmail, setLoginEmail] = useState(null);
  const [loginPassword, setLoginPassword] = useState(null);
  const [externalAuthProvider, setExternalAuthProvider] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [returnsProduct, setReturnsProduct] = useState(null);
  const [loggedInCompanyId, setLoggedInCompanyId] = useState(null);
  const companyId = router.query?.companyId?.toUpperCase() || getEnvVar('REACT_APP_ORGANISATION_ID');

  const authUrl = `${getEnvVar('REACT_APP_DODDLE_API_URL')}/v2/oauth/token`;
  const authApiString = `${getEnvVar('REACT_APP_DODDLE_API_KEY')}:${getEnvVar('REACT_APP_DODDLE_API_SECRET')}`;
  const localExternalAuth = process.env.NODE_ENV === 'development' ?? false;
  const auth = Base64.encode(authApiString);
  let authorizationCode;

  const [sessionData, setSessionData] = useSessionAuthData();
  if (!isEmpty(sessionData)) {
    setConfig({
      access_token: sessionData.access_token,
      token_type: sessionData.token_type,
      refresh_token: sessionData.refresh_token,
    });
    setTokenExpiryTime(sessionData.tokenExpiryTime);
    setUserInfo(sessionData.userInfo);
    setLoggedInCompanyId(sessionData.loggedInCompanyId);
    setLoading(false);
  }

  const getAuthRequestBody = () => {
    if (window?.location?.hash) {
      // Take the token from the url hash and use it to get a new token

      authorizationCode = qs.parse(window.location.hash, { ignoreQueryPrefix: true })['#code'];
      const token = qs.parse(window.location.hash, { ignoreQueryPrefix: true })['#id_token'];
      const onlyError = qs.parse(window.location.hash, { ignoreQueryPrefix: true })['#error'];
      const tokenButError = qs.parse(window.location.hash, { ignoreQueryPrefix: true }).error;

      if (tokenButError || onlyError) {
        const error = { response: { authorizationCodeErrorCode: tokenButError || onlyError } };
        return setConfig({
          error: errorHandler.checkInvalidLogin(
            error,
          ),
        });
      }

      if (token || authorizationCode) {
        // Return the token in the format expected by the API
        return qs.stringify({
          grant_type: externalAuthProvider?.grantType,
          [externalAuthProvider?.key]: token || authorizationCode,
        });
      }
    }

    if (loginEmail && loginPassword) {
      return qs.stringify({
        grant_type: 'staff_password',
        username: loginEmail,
        password: loginPassword,
        organisationId: companyId,
        scope: [...staffPermissions, ...PERMISSIONS].join(' '),
      });
    }

    return '';
  };

  /**
   *
   * @param {string} reason optional reason message
   */
  const clearAuth = (reason) => {
    setConfig(reason ? { error: reason } : {});
    setUserInfo(null);
    setTokenExpiryTime(null);
    setLoggedInCompanyId(null);
    sessionStorage.clearSession();

    // If we have an external auth provider, log out of that too
    if (externalAuthProvider) {
      externalAuthProvider?.logout();
    }
  };

  const getAllowedRolesConfig = (
    adminRolesString,
    merchantRolesString,
  ) => {
    const adminRoles = adminRolesString.split(',').filter(Boolean);
    const merchantRoles = merchantRolesString.split(',').filter(Boolean);
    return { adminRoles, merchantRoles };
  };

  const getUserRoleType = (roles) => {
    const ALLOWED_ROLES_CONFIG = getAllowedRolesConfig(
      getEnvVar('REACT_APP_ALLOWED_ADMIN_ROLES'),
      getEnvVar('REACT_APP_ALLOWED_MERCHANT_ROLES'),
    );

    const isAdmin = some(ALLOWED_ROLES_CONFIG.adminRoles, (adminRole) => includes(roles, adminRole));

    const isMerchant = some(ALLOWED_ROLES_CONFIG.merchantRoles, (merchantRole) => includes(roles, merchantRole));

    if (!isAdmin && !isMerchant) {
      return ROLE_TYPES.UNKNOWN;
    }

    if (isAdmin && isMerchant) {
      return ROLE_TYPES.UNKNOWN;
    }

    if (isAdmin) {
      return ROLE_TYPES.ADMIN;
    }

    if (isMerchant) {
      return ROLE_TYPES.MERCHANT;
    }

    return ROLE_TYPES.UNKNOWN;
  };

  const checkIfUsersCompanyIsEnabled = async ({ roleType, organisationId, token }) => {
    switch (roleType) {
      case 'admin': {
        break;
      }
      case 'merchant': {
        const { data } = await axios.get('/api/company-status', {
          params: {
            organisationId,
            token,
            returnsProduct,
          },
          headers: {
            Authorization: token,
          },
        });

        if (!data.companyEnabled) {
          throw new Error('constants.apiErrors.companyIsDisabled');
        }
        break;
      }
      default:
        throw new Error('constants.apiErrors.permissions');
    }
  };
  const getAuth = async () => {
    async function getAuthToken() {
      setLoading(true);

      const body = getAuthRequestBody();
      if (!body) return setLoading(false);
      try {
        const response = await axios.post(authUrl, body, {
          headers: {
            Authorization: `Basic ${auth}`,
          },
        });

        const newConfig = response.data;
        const roles = newConfig?.doddle?.userInfo?.roles;
        const userRoleType = getUserRoleType(roles);

        await checkIfUsersCompanyIsEnabled({
          roleType: userRoleType,
          organisationId: companyId,
          token: `${newConfig?.token_type} ${newConfig?.access_token}`,
        });
        const tokenExpiryDelay = newConfig.expires_in * 1000 - 300;
        const tokenExpiresAt = Date.now() + tokenExpiryDelay;

        setSessionData({
          access_token: newConfig?.access_token,
          token_type: newConfig?.token_type,
          tokenExpiryTime: tokenExpiresAt,
          userInfo: newConfig?.doddle.userInfo,
          loggedInCompanyId: companyId,
          refresh_token: newConfig?.refresh_token,
        });

        setTokenExpiryTime(tokenExpiresAt);
        setUserInfo(newConfig?.doddle.userInfo);
        setConfig(newConfig);
        setLoading(false);
        setLoggedInCompanyId(companyId);
      } catch (exception) {
        setConfig({ error: errorHandler.checkInvalidLogin(exception, loginEmail, companyId, authorizationCode) });
        setLoginEmail(null);
        setLoginPassword(null);
        setLoading(false);
      }
      return null;
    }

    const haveAuthInfo = externalAuthProvider ? Boolean(window && window.location.hash) : Boolean(companyId) && Boolean(loginEmail);
    if (haveAuthInfo) {
      await getAuthToken();
    }
  };

  const getRefreshAuth = async () => {
    async function getAuthToken() {
      setLoading(true);
      const getRefreshToken = sessionStorage.getData(STORAGE_KEY_AUTH_DATA)?.refresh_token;
      const body = qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken,
      });
      try {
        const response = await axios.post(authUrl, body, {
          headers: {
            Authorization: `Basic ${auth}`,
          },
        });

        const newConfig = response.data;

        if (!newConfig?.access_token) {
          clearAuth();
          return;
        }

        const tokenExpiryDelay = newConfig.expires_in * 1000 - 300;
        const tokenExpiresAt = Date.now() + tokenExpiryDelay;

        setSessionData({
          access_token: newConfig?.access_token,
          token_type: newConfig?.token_type,
          tokenExpiryTime: tokenExpiresAt,
          userInfo: newConfig?.doddle.userInfo,
          loggedInCompanyId: companyId,
          refresh_token: getRefreshToken,
        });

        setTokenExpiryTime(tokenExpiresAt);
        setUserInfo(newConfig?.doddle.userInfo);
        setConfig(newConfig);
        setLoading(false);
        setLoggedInCompanyId(companyId);
      } catch (exception) {
        setLoading(false);
        clearAuth(exception.message);
      }
    }

    const haveAuthInfo = externalAuthProvider ? Boolean(window?.location?.hash) : Boolean(companyId);
    if (haveAuthInfo) {
      await getAuthToken();
    }
  };

  useEffect(() => {
    if (!config?.access_token && localConfig?.isDevLogin) {
      setLoginEmail(localConfig.username);
      setLoginPassword(localConfig.password);
    }
  }, []);

  useEffect(() => {
    if (loginEmail && loginPassword) {
      void getAuth();
    }
  }, [loginEmail, loginPassword]);

  useEffect(() => {
    if (externalAuthProvider && window?.location?.hash) {
      void getAuth();
    }
  }, [externalAuthProvider]);

  const refreshTokenIfExpired = () => {
    const isTokenExpired = () => Date.now() > tokenExpiryTime;
    // If the token is expired and we don't have an external auth provider, we need to refresh the token
    if (isTokenExpired() && !externalAuthProvider) {
      void getRefreshAuth();
    }
    // If the token is expired and we have an external auth provider, we need to clear the auth
    if (isTokenExpired() && externalAuthProvider) {
      clearAuth('constants.apiErrors.codeExpired');
    }
  };
  let interval;

  useEffect(() => {
    if (tokenExpiryTime) {
      interval = setInterval(refreshTokenIfExpired, 10000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => { };
  }, [tokenExpiryTime]);

  useEffect(() => {
    if (loggedInCompanyId && companyId !== loggedInCompanyId) {
      clearAuth();
      sessionStorage.startSession(companyId);
    }
  }, [companyId, loggedInCompanyId]);

  const getAccessToken = () => config?.access_token ?? '';
  const getTokenType = () => config?.token_type ?? '';
  const getToken = () => `${getTokenType()} ${getAccessToken()}`;
  const getTokenExpiresIn = () => config?.expires_in ?? '';
  const getUserId = () => userInfo?.userId ?? '';
  const createExternalAuthProvider = (authProps) => {
    const authProvider = ExternalAuth(authProps, localExternalAuth);
    setExternalAuthProvider(authProvider);
  };

  return (
    <AuthContext.Provider value={{
      getAccessToken,
      getTokenType,
      getToken,
      getTokenExpiresIn,
      getAuth,
      getUserId,
      getAllowedRolesConfig,
      setLoginEmail,
      setLoginPassword,
      createExternalAuthProvider,
      setReturnsProduct,
      externalAuthProvider,
      isUserLoggedIn: Boolean(config?.access_token),
      loading,
      loggedInCompanyId,
      clearAuth,
      getRefreshAuth,
      ...config,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.defaultProps = {
  localConfig: null,
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  localConfig: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    rootOrgId: PropTypes.string,
    isDevLogin: PropTypes.bool,
  }),
};

export default AuthContextProvider;
