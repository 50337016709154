import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import merge from 'deepmerge';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { AuthContext } from '@/contexts/auth-context';
import { ValidateAddressContext } from '@/contexts/validate-address-context';
import publish from '@/utilities/publishing';
import addresses from '@/utilities/addresses';
import { LocaleContext } from '@/contexts/locale-context';
import { errorHandler } from '@/utilities/error-handling';
import logging from '@/utilities/telemetry/sentry';

const { logEvent } = logging;

// For some api's we need to wait a bit for upstream to have updated (e.g. when uploading images)

export const ChangesContext = createContext();
export const ChangesConsumer = ChangesContext.Consumer;

const changeStatus = {
  NO_CHANGES: Symbol('no_changes'),
  PUBLISHING: Symbol('publishing'),
  PUBLISHED: Symbol('published'),
};

const ChangesContextProvider = ({ children }) => {
  const WAIT_FOR_UPSTREAM_TO_HAVE_UPDATED = 3000;
  const { getAccessToken, getTokenType } = useContext(AuthContext);
  const { getLocaleContextValue } = useContext(LocaleContext);
  const {
    merchantId,
    getRemoteConfig: getMerchantContextRemoteConfig,
    remoteConfigUpdated: merchantRemoteConfigUpdated,
    getProductName,
    getApplicationConfigVersion,
    getFeature,
    getDefaultWarehouse,
    getConfigValue,
    homeCountryCode,
  } = useContext(MerchantContext);
  const {
    getCarrierName, getAdminConfigValue, isAdminReviewMode, adminModeMerchantId, getAdminProductName, isAdmin, getAdminFeature,
  } = useContext(AdminContext);
  const { addressValidationRequired } = getFeature('details');
  const { newAddressEnabledByDefault, homeCountryCodeSetToWarehouse } = getFeature('addresses');
  const isIntegrated = getConfigValue('isIntegrated');

  const {
    setAddress,
    isValid: validatedAddress,
    reset: resetAddressValidation,
    error: validateAddressError,
    addressCarrierRestrictions,
    setInfoMessageForAddress,
  } = useContext(ValidateAddressContext);

  const [changes, setChanges] = useState([]);
  // object used for sharing any kind of data between different components/contexts
  const [sharedData, setSharedData] = useState({});
  const [clearChanges, setClearChanges] = useState(false);
  const [shouldPublishChanges, setShouldPublishChanges] = useState(false);
  const [shouldClearChanges, setShouldClearChanges] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [error, setError] = useState(null);
  const [publishingState, setPublishingState] = useState(changeStatus.NO_CHANGES);
  const [clientToken, setClientToken] = useState(null);
  const [upstreamUpdateDelay, setUpstreamUpdateDelay] = useState(WAIT_FOR_UPSTREAM_TO_HAVE_UPDATED);

  const addChange = ({
    type,
    key,
    value,
    originalValue,
    valueId,
  }) => {
    const getChanges = (currentChanges) => {
      let added = false;
      const newChanges = currentChanges.map((change) => {
        if (JSON.stringify(change.key) === JSON.stringify(key)) {
          added = true;
          if (value === originalValue) return null;
          return {
            type, key, value, originalValue, valueId,
          };
        }
        return change;
      }).filter(Boolean);

      if (!added) {
        newChanges.push({
          type, key, value, originalValue, valueId,
        });
      }
      return newChanges;
    };
    if (type === 'WAREHOUSE') {
      resetAddressValidation();
    }
    setChanges((currentChanges) => getChanges(currentChanges));
  };

  const removeChange = (key) => {
    const currentChanges = changes;
    const newChanges = currentChanges.filter((change) => change.key !== key);
    setChanges([...newChanges]);
  };

  const discardChanges = () => setChanges([]);

  useEffect(() => {
    if ((merchantRemoteConfigUpdated || isAdmin) && clearChanges) {
      discardChanges();
      setClearChanges(false);
      // sets an info message for the warehouse after creation
      if (addressCarrierRestrictions?.enabled) {
        setInfoMessageForAddress({ locationId: getDefaultWarehouse()?.locationId });
      }
    }
  }, [merchantRemoteConfigUpdated, clearChanges]);

  const validateWarehouse = (addressChanges) => {
    const existingAddress = getDefaultWarehouse();
    const updatedAddress = {};
    addressChanges.forEach((changeObj) => {
      updatedAddress[changeObj.valueId] = changeObj.value;
    });
    const fullAddress = merge(existingAddress, {
      place: {
        address: updatedAddress,
      },
    });
    setAddress(addresses.getUserAddressURLString(fullAddress.place.address));
  };

  const handlePublish = async () => {
    setPublishingState(changeStatus.PUBLISHING);
    setError(null);
    try {
      const token = clientToken ? `${clientToken.token_type} ${clientToken.access_token} ` : `${getTokenType()} ${getAccessToken()}`;
      await publish({
        changes,
        merchantId: isAdminReviewMode ? adminModeMerchantId : merchantId,
        configCarrier: getCarrierName(),
        token,
        rootOrgId: getCarrierName(),
        productName: isAdmin ? getAdminProductName() : getProductName(),
        warehouseAddress: getDefaultWarehouse(),
        applicationVersion: getApplicationConfigVersion(),
        getConfigValue,
        getFeature,
        sharedData,
        getAdminConfigValue,
        getAdminFeature,
        getLocaleContextValue,
        newAddressEnabledByDefault: isIntegrated ? newAddressEnabledByDefault : true,
        homeCountryCode,
        homeCountryCodeSetToWarehouse,
      });
      setPublishingState(changeStatus.PUBLISHED);
      setTimeout(async () => {
        await getMerchantContextRemoteConfig();
        if (shouldClearChanges) {
          setClearChanges(true);
          setPublishingState(changeStatus.NO_CHANGES);
        }
      }, upstreamUpdateDelay);
    } catch (e) {
      logEvent({
        eventName: 'publishing_failure',
        message: `Publishing failed for merchant ${isAdminReviewMode ? adminModeMerchantId : merchantId}`,
      });
      setError({ message: errorHandler.defaultError(e), error: e });
      setTimeout(() => {
        setPublishingState(changeStatus.NO_CHANGES);
        if (!clientToken) {
          setError(null);
        }
      }, upstreamUpdateDelay);
    } finally {
      setShouldPublishChanges(false);
    }
  };

  const areUnsavedChanges = () => changes.length > 0;

  useEffect(() => {
    if (changes.length > 0 && publishingState !== changeStatus.PUBLISHED) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [changes, publishingState]);

  useEffect(() => {
    if (addressValidationRequired && validatedAddress && shouldPublishChanges) {
      void handlePublish();
    }
  }, [validatedAddress]);

  useEffect(() => {
    if (validateAddressError) {
      setError({ message: errorHandler.defaultError(validateAddressError), error: validateAddressError });
      setShouldPublishChanges(false);
    }
  }, [validateAddressError]);

  useEffect(() => {
    if (shouldPublishChanges) {
      const addressChanges = changes.filter((change) => change.type === 'WAREHOUSE');
      if (addressChanges.length && addressValidationRequired && !validatedAddress) {
        validateWarehouse(addressChanges);
      } else {
        void handlePublish();
      }
    }
  }, [shouldPublishChanges]);

  return (
    <ChangesContext.Provider
      value={
        {
          discardChanges,
          addChange,
          removeChange,
          publishChanges: ({ appToken = false, clearState = true, updateDelay = WAIT_FOR_UPSTREAM_TO_HAVE_UPDATED } = {}) => {
            if (!shouldPublishChanges) {
              setShouldPublishChanges(true);
              setClientToken(appToken);
              setShouldClearChanges(clearState);
              setUpstreamUpdateDelay(updateDelay);
            }
          },
          areUnsavedChanges,
          unsavedChanges,
          sharedData,
          setSharedData,
          validateWarehouse,
          changeStatus,
          setPublishingState,
          publishingState,
          changes,
          error,
        }
      }
    >
      {children}
    </ChangesContext.Provider>
  );
};

ChangesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChangesContextProvider;
